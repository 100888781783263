import React from 'react'
import Layout from '../components/layout'

export default function contact() {
  return (
    <Layout>
      <div className="container">
        <h1>Contact</h1>
        <div className="row align-items-start">
          <div className="col">
            <div className="contact-section">
              <div className="contact">
                <a href="mailto:stefan.falk@dreammill.se">
                  <h3>Stefan Falk</h3>
                  <p>Client Director</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:per@dreammill.se">
                  <h3>Per Renhed</h3>
                  <p>Creative Director</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:marco@dreammill.se">
                  <h3>Marco Taavo</h3>
                  <p>Art Director</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:marcus@dreammill.se">
                  <h3>Marcus Löf</h3>
                  <p>Graphic Designer</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:viktor@dreammill.se">
                  <h3>Viktor Ohlin</h3>
                  <p>Web Director</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:jennifer@dreammill.se">
                  <h3>Jennifer Hammervald</h3>
                  <p>Digital Analyst</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:johannes@dreammill.se">
                  <h3>Johannes Berner</h3>
                  <p>Content Creator</p>
                </a>
              </div>
              <div className="contact">
                <a href="mailto:mikael@dreammill.se">
                  <h3>Mikael Göthage</h3>
                  <p>Content Creator</p>
                </a>
              </div>
            </div>
          </div>
          <div className="col maps">
            <h2>Office - Stockholm</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.8212930070392!2d18.07314251640012!3d59.31924101887319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f77fb647e980b%3A0xd8cd734a291eb0fe!2sKatarinav%C3%A4gen%2015%2C%20116%2045%20Stockholm!5e0!3m2!1ssv!2sse!4v1643795545844!5m2!1ssv!2sse"
              style={{
                border: '0',
                width: '350px',
                height: '300px',
                filter: 'grayscale(100%)',
              }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>

            <h2>Office - Åre</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1786.7574246228319!2d13.093688316499401!3d63.395502785605785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x466e0b72741d15d9%3A0x5ea32c651bfd01ee!2sHouse%20Be!5e0!3m2!1ssv!2sse!4v1643797383288!5m2!1ssv!2sse"
              style={{
                border: '0',
                width: '350px',
                height: '300px',
                filter: 'grayscale(100%)',
              }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>

            <h2>Studio - Göteborg</h2>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2131.0500565822626!2d11.97247701636241!3d57.715607446379124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff49d0a679dad%3A0xd0818a31f6ebacd7!2sTorsgatan%203A%2C%20411%2004%20G%C3%B6teborg!5e0!3m2!1ssv!2sse!4v1643797315588!5m2!1ssv!2sse"
              style={{
                border: '0',
                width: '350px',
                height: '300px',
                filter: 'grayscale(100%)',
              }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}
